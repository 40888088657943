<template>
  <div class="bannerHome">
    <img v-if="$store.state.homesfetched" class="backgroundImage" :src="$store.state.homes[0].backgroundImage">
    <img class="dnaImage" src="../assets/DNA_human_large_full.png" alt="">
    <div class="bbTitle" v-if="$store.state.homesfetched">
      <div v-html="$store.state.homes[0].title" class="bannerTitle"></div>
    </div>
    <div class="bbContent" v-if="$store.state.homesfetched">
      <div v-html="$store.state.homes[0].content" class="bannerContent"></div>
    </div>
    <div class="moreButton" @click="$store.state.bbtn = !$store.state.bbtn"></div>  

  </div>
</template>

<script>
export default {
};
</script>

<style>


.bannerHome {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backgroundImage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.dnaImage {
  position: absolute;
  width: 65%;
  left: 50%;
}

.moreButton {
  transform: rotate(90deg) translate(0, 0);
  height: 6vh;
  width: 6vh;
  background-image: url(../assets/arrow.svg);
  cursor: pointer;
  /* filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3)); */
  position: absolute;
  bottom: 4vh;
  animation: bounce-in 1s;

}

.bbTitle {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 900;
  width: 30vh;
  height: 30vh;
  background-color: #e8434b;
  border-radius: 50%;
  left: 13%;
  top: 1vh;
  z-index: 1;
}

.bannerTitle {
  width: 80%;
  height: 80%;
  color: white;
  transform: translateY(32%) translateX(12%);
}

.bbContent {
  position: absolute;
  font-size: 1.5rem;
  width: 35%;
  height: 60vh;
  border-radius: 50%;
  left: 3%;
  top: 33vh;
}

.bannerContent {
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  }
.bannerContent p {
    margin: 0;
    height: 3rem;
}

.bannerContent p a {
    color: inherit;
}



.bannerContent p::before {
  display: inline-block;
  content: "";
  background-color: rgba(214, 158, 158, 0.904);
  margin-right: 10px;
  margin-left: 20px;
  width: 2.2vh;
  height: 2.2vh;
  border-radius: 50%;
}

.bannerContent p:nth-child(2n)::before{
  display: inline-block;
  content: "";
  background-color: #d64f4fe8;
  margin-right: 10px;
  margin-left: 165px;
  width: 2.5vh;
  height: 2.5vh;
  border-radius: 50%;
  position: relative;
}

.bannerContent p:nth-child(3n)::before{
  display: inline-block;
  content: "";
  background-color: #ea212eb6;
  margin-right: 10px;
  margin-left: 135px;
  width: 3.8vh;
  height: 3.8vh;
  border-radius: 50%;
  position: relative;
}

.bannerContent p:nth-child(5n)::before{
  display: inline-block;
  content: "";
  background-color: #f76868c9;
  margin-right: 10px;
  margin-left: 110px;
  width: 3vh;
  height: 3vh;
  border-radius: 50%;
}

@keyframes bounce-in {
  35% {
    transform:translateY(0) rotate(90deg);
  }
  52% {
    transform: translateY(-1vh) rotate(90deg);
  }
  70% {
    transform: translateY(0) rotate(90deg);
  }
  85% {
    transform: translateY(-1vh) rotate(90deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

</style>